.container {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  .content {
    display: flex;
    flex-direction: row;
  }
  .divider {
    margin: 0 2px 0 8px;
  }
  .button {
    height: auto;
    border-radius: 4px;
    padding: 4px;
    line-height: 0;
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";