.container {
  padding-top: 0.75rem;
}

.select {
  width: clamp(10ch, 100%, 30ch);
}

.updateButton {
  margin-top: 0.625rem;
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";