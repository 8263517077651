.annotationStatusContainer {
  display: flex;
  flex-flow: row;
  gap: 0.5rem;
  color: @text-light;
  font-size: 0.8rem;
  align-items: flex-start;

  .authorStatusTimestamp {
    margin-top: 0.1rem;

    .authorName {
      color: black;
      font-weight: 500;
    }
    .statusMessage {
      margin-left: 0.35rem;
    }
    .timestamp {
      &:before {
        content: '•';
        margin: 0 0.35rem;
      }
    }
  }
}
.annotationMessageContainer {
  display: flex;
  flex-flow: row;
  gap: 0.5rem;

  .authorContentContainer {
    flex: 1;
    border-radius: 4px;
    margin-top: -0.4rem;
    padding: 0.5rem;
    background: @teal-light;
    color: @text-light;

    .authorTimestamp {
      display: flex;
      gap: 0.5rem;
      align-items: flex-end;
      font-size: 0.8rem;
      .authorName {
        color: black;
        font-weight: 500;
      }
    }
    .content {
      margin-top: 0.25rem;
      font-size: 0.9rem;
      color: black;
    }
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";