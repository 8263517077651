@import "/theme.less";

.container {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 2px solid @text-color-secondary;
  font-size: 30px;
  text-align: center;
  color: @text-color-secondary;
  cursor: default;
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";