@import "/theme.less";

.gridOuter {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  margin-top: 4px;
  @media screen and (min-width: @screen-xxl) {
    gap: 18px;
    font-size: 18px;
    max-width: 2100px;
    margin: auto;
    margin-top: 21px;
  }

  .stackGroup {
    display: flex;
    flex: 1;
    gap: 12px;
    width: 100%;
    padding: 16px 4px 4px 4px;
    position: relative;
  }

  .enclosure {
    position: relative;
    border: 1px dashed @teal;
    border-radius: 8px;

    .tag {
      display: flex;
      gap: 12px;
      position: absolute;
      padding: 1px 4px;
      line-height: 1.2;
      background-color: white;
      border-radius: 4px;
      box-shadow: @shadow-md;
      z-index: 5;
    }

    .enclosureTag {
      top: -10px;
      left: 50%;
      transform: translate(-50%, 0);
      border: 1px solid @teal;
    }

    .stackTagLeft {
      top: 2px;
      left: 25%;
      transform: translate(-50%, 0);
    }
    .stackTagRight {
      top: 2px;
      left: 75%;
      transform: translate(-50%, 0);
    }
  }

  .stackCard {
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &:hover {
      box-shadow: @shadow-md;
    }
    .header {
      padding: 6px 30px 2px 30px;

      border-bottom: 1px solid #f0f0f0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .headerSection {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
    .content {
      flex: 1;
      padding: 6px 32px;

      .carousel {
        width: 100%;
        height: 100%;

        :global(.carousel-slider) {
          height: 100%;
          overflow: visible;
        }

        :global(.thumbs-wrapper) {
          display: none;
        }

        .carouselItem {
          width: 100%;
          height: 100%;
        }

        .arrowLeft {
          cursor: pointer;
          position: absolute;
          left: -28px;
          top: calc(50% - 16px);
          z-index: 2;
        }

        .arrowRight {
          cursor: pointer;
          position: absolute;
          right: -28px;
          top: calc(50% - 16px);
          z-index: 2;
        }
      }
    }
    @media screen and (min-width: @screen-xxl) {
      gap: 18px;
      .header {
        padding: 12px 24px;
      }
      .content {
        flex: 1;
        padding: 8px 32px;
      }
    }

    .displayField {
      display: flex;
      align-items: center;
      min-width: 4ch;
      line-height: 1;
      border-radius: 4px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15) inset;
      padding: 1px 4px;
      color: @teal;
      margin-left: 4px;
    }
    .listItem {
      display: flex;
      justify-content: space-between;
      gap: 8px;
    }
  }
  .listContainer {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  .listLeft {
    margin-right: -40px;
  }
}

.highlightVal {
  font-weight: 500;
  color: @teal;
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";