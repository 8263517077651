@import "/theme.less";

@severityNormal: #50c544;
@severityLow: #f3b416;
@severityMedium: #fa7616;
@severityHigh: #ff0f0f;
@severityCritical: #a90000;
@acknowledged: #848484;

.container {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  @toolbar-width: 256px;

  .toolbar {
    display: flex;
    flex-direction: column;
    height: calc(100vh - @layout-header-height - 48px);
    width: @toolbar-width;
    background: white;
    color: #00000073;
    .title {
      font-weight: 500;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      width: 20px;
      height: 20px;
    }
    .divider {
      padding: 0 !important;
      margin: 12px 0;
    }
    .optionContentLeft {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    .checkboxContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      :global(.ant-checkbox-wrapper) {
        pointer-events: none;
      }
    }
  }
  .toolbar > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    gap: 12px;
    cursor: pointer;
    &:hover {
      background: #f5f5f5;
    }
  }
  .alarmsContainer {
    flex: 1;
    margin-left: 24px;
    background-color: white;

    .searchBar {
      display: flex;
      flex-direction: row;
      padding: 16px;
      width: 100%;
      gap: 8px;
      .searchinput {
        flex: 1;
      }
      .select {
        min-width: 19ch;
      }
    }

    .list {
      max-height: calc(100vh - @layout-header-height - 88px);
      overflow-y: auto;
    }
  }
}

.alarmContainer {
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.1);
  &:hover {
    background: #f5f5f5;
  }
  &.acknowledged {
    background: #f0f0f0;

    .alarmContent .alarmContentLeft {
      color: #848484;
    }
  }

  .alarmContent {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 100%;
    padding: 18px;
    padding-left: 8px;

    .alarmContentLeft {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      padding-left: 16px;
      font-weight: 500;
      .icon {
        font-size: 20px;
      }
    }

    .alarmContentRight {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 22px;

      .button {
        margin: 0 8px;
        width: 126px;
      }
      .icon {
        display: flex;
        align-items: center;
        padding: 4px;
      }
      .alarmTime {
        color: rgba(0, 0, 0, 0.56);
        width: 148px;
      }
    }
  }
}

.severityLowColor {
  color: @severityLow;
}
.severityMediumColor {
  color: @severityMedium;
}
.severityHighColor {
  color: @severityHigh;
}
.severityCriticalColor {
  color: @severityCritical;
}
.severityNormalColor {
  color: @severityNormal;
}
.acknowledgedColor {
  color: @acknowledged;
}

.severityNormalBackground {
  background: @severityNormal;
}
.severityLowBackground {
  background: @severityLow;
}
.severityMediumBackground {
  background: @severityMedium;
}
.severityHighBackground {
  background: @severityHigh;
}
.severityUrgentBackground {
  background: @severityCritical;
}
.acknowledgedBackground {
  background: @acknowledged;
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";