.sidebar {
  position: fixed !important;
  overflow: auto;
  height: 100vh;
}
.content {
  margin-left: 350px;
  background: @bg-light;
  transition: 0.2s;
  .contentContainer {
    height: 100%;
    padding: 0;
    margin: 1.5rem;
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";