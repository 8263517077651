.cellContainer {
  display: flex;
}

.favoriteContainer{
  padding: 16px;
  padding-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nameContainer {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 16px;
  padding-left: 0;
  color: @text-color;
  transition: all 0.3s;
  cursor: pointer;
  @media screen and (max-width: @screen-md) {
    padding: 12px;
  }

  .icon {
    font-size: 17px;
    display: flex;
  }
  &:hover {
    color: @teal;
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";