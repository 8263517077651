.backContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  color: @text-color-secondary;
  cursor: pointer;
  padding: 2px;
  width: fit-content;
  transition: color ease-in-out 0.1s;
  &:hover {
    color: @teal;
    transition: color ease-in-out 0.1s;
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";