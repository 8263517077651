.container {
  display: flex;
  white-space: nowrap;
  font-size: 0.9rem;
  .title {
    color: @eh2-green;
    font-weight: 500;
  }
  .divider {
    margin: 0 0.25rem;
    font-size: 0.8rem;
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";