@import "/theme.less";

.siderContainer {
  position: fixed;
  height: "100vh";
  top: 48px;
  bottom: 0px;
  width: 318px;
  .threadContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    .threadTool {
      margin: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      .selectStatus {
        width: 125px;
      }
      .threadCheckbox {
        display: flex;
        flex-direction: row;
        gap: 4px;
      }
    }
    .threads {
      overflow: auto;
      flex-grow: 1;
      .threadItemContainer{
        display: flex;
        flex-direction: row;
        max-width: 95%;
        padding-left: 16px;
        gap: 8px;
        .threadName {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          margin-right: 0px;
          padding: 0 15px 0 0;
        }
        .threadText {
          margin: auto;
          overflow-wrap: break-word;
          padding: 5px 15px 0 0;
        }
      }
    }
    :global(.ant-list-item:hover) {
      background-color: #F0F0F0;
      cursor: pointer;
    }
  }

  .commentContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    .comments {
      overflow: auto;
      flex-grow: 1;
      .commentName {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        margin-right: 0px;
        padding: 0 15px 0 15px;
      }
      .commentText {
        overflow-wrap: break-word;
        margin: 5px 15px 0px 15px;
        padding: 0 10px 0 0;
        .background {
          display: inline-block;
          border-radius: 0 20px 20px 20px;
          padding: 0 10px 0 10px;
          background-color: #F5F5F5;
        }
      }
    }

    :global(.ant-list-item) {
      border: none;
      width: 100%;
    }
  }
}


.input {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 20px;
  .inputButton {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-start;
  }
}

.closeButton {
  border: none;
  box-shadow: none;
  color: @text-color-secondary;
}

.textContainer {
  width: 100%;
}

.spinContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.timestamp {
  color: @text-light;
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";