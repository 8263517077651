@import "/theme.less";
.actions {
  display: flex;
  gap: 10px;
  .actionLink {
    padding: 0;
    color: @link-color !important;
    &:hover {
      color: @link-hover-color !important;
    }
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";