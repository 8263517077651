.boxHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .boxTitle {
    font-size: 1rem;
    font-weight: 500;
    color: @teal;
    flex: 1;
  }
}
.boxContent {
  position: relative;
  flex: 1;
  height: 300px;
  border: 1px solid @teal-medium;
  border-radius: 4px;
  &:after {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.1);
  }
}

.uploadTable {
  height: 100%;
  overflow: auto;

  :global(.ant-spin-nested-loading),
  :global(.ant-spin-container),
  :global(.ant-table),
  :global(.ant-table-container),
  :global(.ant-table-content) {
    height: 100%;
    table {
      height: min-content;
    }
  }
  :global(.ant-table-thead) {
    :global(.ant-table-cell) {
      padding: 1rem;
    }
  }
  :global(.ant-table-cell) {
    padding: 0.5rem 1rem;
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";