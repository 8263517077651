@import "/theme.less";

.container {
  display: flex;
  gap: 6px;
  flex: 1;

  .timeControls {
    display: flex;
    gap: 6px;
    :global(.ant-picker-small) {
      border-radius: 2px;
    }
  }

  .buttonLeft {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .buttonRight {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  :global(.ant-switch) {
    background: @teal !important;
  }

  .intervalPicker {
    transition: all 0s;
    width: 150px;
    :global(.ant-select-selector) {
      transition: all 0s !important;
    }
  }

  .pollIntervalSwitcher {
    display: flex;
    align-items: center;
    .refreshButton {
      display: flex;
      align-items: center;
      padding: 0 0.6rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:global(.ant-btn-sm) {
        padding: 0 0.4rem;
      }
    }
    .selectButton {
      :global(.ant-select-selector) {
        width: 74px;
        border-left: 0;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
      &.small {
        :global(.ant-select-selector) {
          width: 68px;
        }
      }
    }
  }
}

:global(.ant-picker-preset > .ant-tag) {
  border-radius: 2px;
}

.switcher {
  min-width: fit-content;
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";