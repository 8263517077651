.titleActionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .backIcon {
    margin-right: 0.5rem;
  }
}
.actionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .snoozeIcon {
    color: @text-color-secondary;
    cursor: pointer;
    font-size: 1.25rem;
    &.active {
      color: @teal;
    }
  }
}
.alarmsTableContainer {
  margin-top: 1rem;
  .alarmsTable {
    margin-top: 1rem;

    .noDescription {
      color: @text-color-secondary;
    }
    .notifiedUsers {
      display: flex;
      gap: 0.25rem;

      .userAvatar {
        cursor: default;
      }
    }
    .actionButton {
      color: @link-color;
    }
  }
}
:global(.ant-popover-inner-content) {
  display: flex;
  gap: 0.25rem;
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";