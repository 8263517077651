@import "/theme.less";

.modal {
  background: transparent;
  :global(.ant-modal-content) {
    background: transparent;
    box-shadow: none;
    .input {
      padding: 1.25rem 1.5rem;
      font-size: 1.25rem !important;
      box-shadow: @shadow-lg;
      :global(.ant-input-suffix) {
        display: none;
      }

      .suffixContainer {
        display: flex;
        gap: 0.25rem;
        font-size: 1rem;
        color: @text-color-secondary;
      }
    }

    :global(.ant-input-affix-wrapper-status-success .ant-input-suffix) {
      display: flex;
      @media screen and (max-width: @screen-sm) {
        display: none;
      }
    }

    :global(.ant-form-item-explain-error) {
      display: none;
    }

    .bottomContainer {
      display: flex;
      flex-direction: column;
      min-height: 24rem;
      padding: 1.25rem 1.5rem;
      font-size: 0.9375rem;
      background: @white;
      border-radius: @border-radius-base;
      box-shadow: @shadow-lg;

      .animationContainer {
        width: 24rem;
        height: 24rem;
        align-self: center;
      }

      .responseTitle {
        font-size: 1.25rem;
        font-weight: 500;
        padding-bottom: 0.375rem;
      }
      .answerContainer {
        max-height: 68vh;
        min-width: min(24rem, 100%);
        text-align: justify;
        overflow-y: scroll;
      }
    }
    @media screen and (max-width: @screen-sm) {
      padding: 0;
    }
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";