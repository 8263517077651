@import "/theme.less";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 16px;

  .card {
    .title {
      font-weight: 500;
      padding-bottom: 16px;
    }
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 4px;
    padding: 16px 24px;
    background-color: white;
    box-shadow: @shadow-lg;
    .plotContainer {
      flex: 1;
    }
    .content {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: 1fr 1fr;

      .stackContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .listItem {
          display: flex;
          align-items: center;
          gap: 2px;
        }
        .name {
          font-weight: 500;
        }
        .val {
          line-height: 1;
          width: 4.5ch;
          font-size: 24px;
          font-weight: 500;
          color: @teal;
        } 
        .unit {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
        }
      }
    }
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";