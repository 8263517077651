.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headerRight {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 0.5rem;
      .headerButton {
        display: flex;
        align-items: center;
        .icon {
          height: 20px;
        }
      }
      .burgerIcon {
        font-size: 1.2rem;
      }
      :global(.ant-btn) {
        display: flex;
        :global(.anticon) {
          display: flex;
          align-items: center;
        }
      }
    }
    .closeButton {
      color: @teal;
      font-size: 1.5rem;
    }
  }
  
  .shiftIcon {
    margin-right: 0.25rem;
  }
  
@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";