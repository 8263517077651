.title {
  display: flex;
  justify-content: flex-start;
  margin: 6px 0;
  padding: 0 0.24rem;
  height: calc(@title-container-height - 12px);
  line-height: calc(@title-container-height - 12px);
  border-radius: 6px;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  .link {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    .logoContainer {
      padding: 0 4px;
    }
    .logo {
      display: inline-block;
      height: @title-logo-size;
      vertical-align: middle;
    }
    .organizationLogo {
      display: inline-block;
      height: @title-logo-size;
      vertical-align: middle;
      border-radius: 50%;
    }
    .organizationName {
      margin-left: 16px;
      color: @text-color;
      font-size: 1rem;
      font-weight: 500;
    }
    :global(.ant-skeleton-avatar-square) {
      width: 150px !important;
      height: @title-logo-size !important;
      margin-left: 16px !important;
      vertical-align: middle !important;
    }
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";