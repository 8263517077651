.recordText {
  display: inline-block;
  height: 24px;
}
.recordInput {
  transform: translate(-8px, -1px);
}
.recordButton {
  color: @table-link;
  cursor: pointer;
  margin-right: 0.5rem;
}
.measurePlaceholder {
  color: @text-disabled;
  display: inline-block;
  height: 24px;
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";