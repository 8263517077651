@import "/theme.less";

footer {
  text-align: center;
  font-size: 14px;

  .links {
    a {
      color: inherit;
      @margin-horizontal: 12px;
      display: inline-flex;
      margin-top: 10px;
      margin-left: @margin-horizontal;
      margin-right: @margin-horizontal;
    }
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";