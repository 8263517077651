@import "/theme.less";

.card {
    display: flex;
    flex-direction: column;
    padding: 8px 12px;
    background-color: white;
    border-radius: 4px;
    box-shadow: @shadow-md;
    .plotContainer {
      flex: 1;
    }

    .title {
      cursor: pointer !important;
      &:hover {
        color: @teal;
      }
    }
  }
@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";