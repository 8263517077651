.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    .titleContainer {
      display: flex;
      align-items: center;

      .moduleIcon {
        color: #7B61FF;
        transform: scale(150%);
      }
      .title {
        margin: 0 0 0 0.5rem;
      }
    }
    .thresholdContainer {
      margin: 1rem 0 0;

      .thresholdText {
        margin: 0.25rem 0;
        font-weight: 500;
        white-space: nowrap;
      }
      .sliderContainer {
        display: flex;

        .sliderThreshold {
          width: 200px;
          margin-right: 1rem;
        }
        .inputThreshold {
          width: 200px;
        }
      }
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    flex: 1;
    margin: 1rem 0 1.5rem;
    .item {
      display: flex;
      height: 100%;
      &.gridTopRight {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem;
      }
      &.gridBottomRight {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
          "chart chart kpi kpi"
          "chart chart kpi kpi";
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem;
        .pieChart {
          grid-area: chart;
        }
      }
    }
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";