@import "/theme.less";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  box-shadow: 0px 1px 4px -4px rgba(0, 0, 0, 0.1), 0px 1px 20px rgba(0, 0, 0, 0.1);
  background: @white;
  padding: 24px;
  @media screen and (max-width: @screen-md) {
    padding: 12px;
  }
  .select {
    width: 100%;
  }

  .content {
    width: clamp(600px, 60%, 1200px);
  }
  .form {
    margin: 0 !important;
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";