.titleContainer {
  display: flex;
  font-size: 18px;
  align-items: center;
  gap: 8px;

  .icon {
    font-size: 22px;
    color: #fd4d4f;
  }
}

.description {
  font-size: 14px;
  .orgText {
    font-weight: bold;
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";