.plotContainer {
  overflow: hidden;
  &:hover {
    overflow: visible;
  }
  position: relative;
  height: 100%;
}

.plot {
  height: 100%;
  :global(.nsewdrag) {
    cursor: crosshair !important;
  }

  :global(.main-svg) {
    overflow: visible !important;
  }
}

:global {
  .main-svg .hoverlayer .legend .scrollbox .groups .legendlines path.js-line {
    stroke-width: 3px !important;
  }
  .infolayer .legend .scrollbox .groups .traces .legendlines path.js-line {
    stroke-width: 3px !important;
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";