.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  .space {
    width: 100%;
    .textContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .secondaryText {
        font-size: 16px;
      }
    }
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";