@import "/theme.less";

@item-height: 60px;
@transition-duration: 200ms;

.container {
  width: 100%;
  height: 100%;
  padding-top: 10px !important;
  padding-bottom: 30px !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  border: none !important;
  overflow-x: hidden;
  overflow-y: overlay !important;
  &:not(.fullyOpen) {
    :global(.ant-motion-collapse) {
      transition: all 0s !important;
    }
  }
  :global {
    .ant-menu-submenu-arrow {
      color: inherit !important;
    }

    .ant-menu-title-content {
      transition-duration: 0ms !important;
      line-height: 1.5;
      user-select: none !important;
    }

    .ant-menu-submenu > .ant-menu {
      background-color: #fff;
    }

    .ant-menu-sub.ant-menu-inline {
      background-color: #fff !important;
    }

    .ant-menu-inline {
      .ant-menu-item {
        .ant-menu-title-content {
          white-space: initial;
          min-width: 169px !important;
        }
      }
    }

    .ant-menu-inline-collapsed {
      .ant-menu-item {
        padding-left: 24px !important;
      }
    }

    .ant-menu-submenu-title {
      height: 45px !important;
      font-weight: 700;
      padding-left: 24px !important;
      background-color: transparent !important;
      color: @eh2-green !important;
    }

    .ant-menu > .ant-menu-item-divider {
      border-color: rgba(0, 139, 138, 0.4);
      margin: 1.625rem 0 !important;
    }
  }
}

.container::-webkit-scrollbar {
  display: none !important;
}

.menuItemContainer {
  cursor: pointer;
}

.menuItem {
  margin: 0 !important;
  height: @item-height !important;
  padding-left: 24px !important;
  width: calc(100% - 6px) !important;
  transition: background-color @transition-duration ease-out,
              box-shadow @transition-duration ease-out,
              border-radius @transition-duration ease-out,
              color 10ms ease-out !important;

  .titleContainer {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    .notificationsBadge {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 0.25rem;
      line-height: 0;
      color: white;
      background: #df5833;
    }
    &.linkContainer {
      align-items: center;
      justify-content: space-between;
      padding-right: 24px;
    }
  }

  &::after {
    display: none !important;
  }
  &.selected {
    background-color: @eh2-green !important;
    box-shadow: 4px 4px 10px rgba(153, 166, 206, 0.54);
    border-radius: 0px 100px 100px 0px;
    color: white !important;

    .titleContainer .notificationsBadge {
      background: @teal-light;
      color: @teal-dark;
    }

    .icon {
      color: white;
    }
  }
  &:hover {
    background-color: #f5f5f5;
    box-shadow: 4px 4px 10px rgba(153, 166, 206, 0.54);
    border-radius: 0px 100px 100px 0px;
  }
}

.iconContainer {
  display: flex !important;
  height: 100% !important;
  min-width: 30px !important;
  align-items: center !important;
  transition: all @transition-duration ease-out !important;
}

.icon {
  font-size: 1.4rem !important;
  line-height: 0 !important;
  transition: all @transition-duration ease-out !important;
}

:global(.ant-menu-submenu-popup) {
  display: none;
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";