@import "/theme.less";

.container {
  width: 100%;
  position: relative;

  .bannerText {
    position: relative;
    padding: 1.25rem 1.5rem 0.5rem 1.5rem;
    color: white;

    z-index: 3;
    @media screen and (max-width: @screen-xs) {
      padding: 1rem;
      font-size: 26px;
    }

    .bannerTitle {
      font-weight: 500;
      font-size: 30px;
    }
    .bannerSubtitle {
      padding-top: 0.75rem;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .topBanner {
    position: absolute;
    left: 0;
    right: 0;
    height: 11.625rem;
    overflow: hidden;
    margin-bottom: -5.625rem;
    z-index: 0;

    background-size: cover;
    border-radius: 0rem 0rem 1rem 1rem;
  }
  .cardsContainer {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 24px;
    max-width: 2000px;
    padding: 24px;
    margin: auto;
    @media screen and (min-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      width: 50%;
      min-width: 600px;
      max-width: 1100px;
      min-height: 300px;
      padding: 24px;
      border-radius: 4px;

      .iconContainer {
        cursor: pointer;
        display: flex;
        padding: 7px;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        box-shadow: 0px 1px 4px -4px rgba(0, 0, 0, 0.10), 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
        width: 100px;
        height: 100px;
      }
      .redirectPrompt {
        font-size: 16px;
        color: @text-color-secondary;
      }
    }
  }
}

.button {
  background: @eh2-green;
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";