@import "/theme.less";

.container {
  height: calc(100vh - @layout-header-height);
  width: 100%;
}

.iframeContainer {
  height: 100%;
  width: 100%;
  background-color: #f4f5f5;

  iframe {
    height: 100%;
    width: 100%;
    border: 0;
    margin: 0;
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";