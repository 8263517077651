@import "/theme.less";

.container {
  width: 100%;
  position: relative;

  .bannerText {
    position: relative;
    padding: 1.25rem 1.5rem 0.5rem 1.5rem;
    color: white;

    z-index: 3;
    @media screen and (max-width: @screen-xs) {
      padding: 1rem;
      font-size: 26px;
    }

    .bannerTitle {
      font-weight: 500;
      font-size: 30px;
    }
    .bannerSubtitle {
      padding-top: 0.75rem;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .topBanner {
    position: absolute;
    left: 0;
    right: 0;
    height: 11.625rem;
    overflow: hidden;
    margin-bottom: -5.625rem;
    z-index: 0;

    background-size: cover;
    border-radius: 0rem 0rem 1rem 1rem;
  }
  .cardsContainer {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    max-width: 1900px;
    padding: 24px;
    margin: auto;
    .card {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     gap: 24px;
     min-height: 300px;
     padding: 24px;
     border-radius: 4px;

     .iconContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-weight: 500;
     }
     .contact {
      font-size: 20px;
      line-height: 2;
      font-weight: 500;
      text-align: center;
     }
    }
  }
}

.button {
  background: @eh2-green;
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";