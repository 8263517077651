@import "/theme.less";

.grid {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, 1fr);
  gap: 16px;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  background-color: white;
  border-radius: 4px;
  box-shadow: @shadow-md;
  .plotContainer {
    flex: 1;
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";