@import "/theme.less";

@toggle-width: 18px;
@toggle-height: 180px;
@toggle-angle: 9px;
@toggle-color: @white;

@toggle-icon-size: 18px;
@toggle-icon-color: #008b8a;

@menu-width-rem: 4.75;
@menu-spacing-rem: 0.5;
@menu-icon-size-rem: 1.8;

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  padding-left: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  pointer-events: none;
  z-index: 10;
}
.drawer {
  height: 180px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform: translateX(calc(100% - @toggle-width));
  transition: transform 0.1s ease-out;
  pointer-events: all;
}

.toggleContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: @toggle-icon-color;
  z-index: 2;
}

.toggleIcon {
  margin-left: -@toggle-width;
  font-size: @toggle-icon-size;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ~"@{menu-width-rem}rem";
  min-height: calc(@toggle-height + 40px);
  background: @white;
  border-radius: 20px 0px 0px 20px;
  box-shadow: 0px 1px 4px -4px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.1));
}
.menuItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ~"@{menu-spacing-rem}rem";
  border: 3px solid;
  border-radius: 50%;
  padding: 8px;
  background: @white;
  transition: transform 0.1s ease-in-out;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.menuItem:hover {
  transform: scale(1.2);
}
.menuItemIcon {
  width: ~"@{menu-icon-size-rem}rem";
  height: ~"@{menu-icon-size-rem}rem";
  font-size: ~"@{menu-icon-size-rem}rem";
}
.tooltip {
  background: @white;
}
.submenuContainer {
  position: relative;
}
.submenu {
  position: absolute;
  top: 50%;
  right: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-50%);
  transition: all 0.2s ease-out;
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";