@import "/theme.less";

.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, 1fr);
  height: 100%;
  gap: 12px;
  font-size: 12px;
  @media screen and (min-width: @screen-xxl) {
    gap: 18px;
    font-size: 18px;
  }

  .stackCard {
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    .header {
      padding: 6px 12px;

      border-bottom: 1px solid #F0F0F0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 4px;
      .headerSection {
        display: flex; 
        align-items: center;
        gap: 4px;
      }
    }
    .content {
      flex: 1;
      padding: 6px 12px;

      .carousel {
        width: 100%;
        height: 100%;

        :global(.carousel-slider) {
          height: 100%;
        }

        .carouselItem {
          width: 100%;
          height: 100%;
        }

        .arrowLeft{
          cursor: pointer;
          position: absolute;
          left: 0;
          top: calc(50% - 16px);
        }

        .arrowRight {
          cursor: pointer;
          position: absolute;
          right: 0;
          top: calc(50% - 16px);
        }
      }
    }
    @media screen and (min-width: @screen-xxl) {
      gap: 18px;
      .header {
        padding: 12px 24px;
  
        border-bottom: 1px solid #F0F0F0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .headerSection {
          display: flex; 
          align-items: center;
          gap: 4px;
        }
      }
      .content {
        flex: 1;
        padding: 8px 16px;
      }
    }

    .displayField {
      width: clamp(fit-content, 100%, 4ch);
      min-width: fit-content;      
      border-radius: 4px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15) inset;
      padding: 2px 4px;
      color: @teal;
      margin-left: 6px;
    }
    .listItem {
      display: flex;
      justify-content: space-between;
    }
  }
  .listContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";