.bottomMenu {
  display: flex;
  justify-content: space-between;

  .bottomMenuButton {
    display: flex;
    align-items: center;
    border: 0;
    border-radius: 0;
    color: @teal;
    font-weight: 500;
    box-shadow: none;
    padding: 1.5rem 2rem 1.5rem 0;
    background: #fafafa;
    transition: background 0.5s ease-in-out;
    .bottomMenuButtonIcon {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      padding-left: 2rem;
      margin-right: 0.5rem;
      border-left: 1px solid #e5e5e5;
    }
    &:first-of-type {
      .bottomMenuButtonIcon {
        border: 0;
      }
    }
    &:hover {
      background: #e5e5e5;
    }
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";