.container {
  height: calc(100% - 24px);
  padding: 0;
  margin-bottom: 24px;

  .select,
  .searchInput {
    width: 24ch;
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    padding: 16px;

    .toolbarLeft {
      display: flex;
      gap: 16px;
    }
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";