@import "/theme.less";

.sidebar {
  height: 100vh;
  padding-top: 48px;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 10;
  box-shadow: @shadow-lg;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  border: none !important;
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";