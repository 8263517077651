.userContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  color: @text-color;
  @media screen and (max-width: @screen-md) {
    padding: 12px;
  }
}
.username {
  color: @text-color-secondary;
}

.actions {
  display: flex;
  gap: 10px;
  .actionLink {
    padding: 0;
    color: #1890ff;
    &:hover {
      color: lighten(#1890ff, 8%);
    }
  }
}

:global {
  td.ant-table-cell.nameCol {
    padding: 0 !important;
  }
  .ant-spin-dot-item {
    background-color: @teal !important;
  }

  .ant-table-tbody > tr > td.ant-table-cell {
    @media screen and (max-width: @screen-md) {
      padding: 6px;
    }
  }
}

@hack: true; @import "/codebuild/output/src2342170373/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";